<template>
	<div :style="bg">
		<Header :title="$t('withdraw.withdrawUsdt')" ></Header>
		
		<div class="column-center-content main-content">
			
			<div class="form-content">
				<label class="form-title">{{$t('home.withdrawType')}}</label>
				<div class="row-content" style="width: 100%; align-items: center; text-align: center; line-height: 40px;">
					<div :class="erc20Style" @click="selectErc20">
						<label>Erc20</label>
					</div>
					<!-- <div :class="arc20Style" @click="selectArc20">
						<label>Arc20</label>
					</div> -->
				</div>
			</div>
			
			<div class="form-content">
				<label class="form-title">{{$t('home.withdrawAddress')}}</label>
				<v-text-field :placeholder="$t('home.withdrawAddress_hint')" color="success" style="width: 100%; padding-top: 5px;" v-model="address"></v-text-field>
			</div>
			
			<div class="form-content">
				<label class="form-title">{{$t('home.withdrawAmount')}}</label>
				<v-text-field type="number" :placeholder="$t('home.withdrawUsdtAmount_hint')" color="success" style="width: 100%; padding-top: 5px;" v-model="amount"></v-text-field>
				
			</div>
			
			<div class="form-content">
				<label class="form-title">{{$t('assets.payPwd')}}</label>
				<v-text-field :placeholder="$t('assets.payPwd_hint')" color="success" style="width: 100%; padding-top: 5px;" v-model="pwd" type="password"></v-text-field>
			</div>
			
			<div v-if="gauth" class="form-content row-content">
				<v-text-field :placeholder="$t('google.googleCode_hint')" color="success" v-model="googleCode">
					<v-icon style="width: 20px; margin-right: 10px; color:#FF0000;" slot="prepend" >
						fa fa-envelope-open-o
					</v-icon>
				</v-text-field>
			</div>
			
			<div class="form-content row-content">
				<v-text-field :placeholder="$t('login.tac_hint')" color="success" v-model="code">
					<v-icon style="width: 20px; margin-right: 10px; color:#FF0000;" slot="prepend" >
						fa fa-envelope-open-o
					</v-icon>
				</v-text-field>
				<mt-button @click="sendCode" type="danger" plain style="height: 30px; border-radius: 15px; font-size: 10px;">{{$t('login.getTac')}}</mt-button>
			</div>
			
			<div class="form-content row-content" style="justify-content: space-between; width: 100%;">
				<div class="form-content" style="margin: 0px 30px;">
					<label class="form-title">{{$t('withdraw.withdrawFee')}}</label>
					<div class="row-content" style="width: 100%;">
						<v-text-field readonly color="success" style="width: 100%; padding-top: 5px; margin-right: 15px;" v-model="usdtFee"></v-text-field>
						<label style="font-size: 18px;">{{$t('common.usdt')}}</label>
					</div>
				</div>
				<div class="form-content" style="margin: 0px 30px;">
					<label class="form-title">{{$t('withdraw.withdrawRealAmount')}}</label>
					<div class="row-content" style="width: 100%;">
						<v-text-field readonly color="success" style="width: 100%; padding-top: 5px; margin-right: 15px;" v-model="realAmount"></v-text-field>
						<label style="font-size: 18px;">{{$t('common.usdt')}}</label>
					</div>
				</div>
			</div>
			
			<div class="btn-content" style="margin: 30px 0px">
				<v-btn :disabled="submitFlag" rounded block @click="showConfirmTip" style="background-color: #009245; color: white; height: 40px;">
					{{$t('common.submit')}}
				</v-btn>
			</div>
			
			<div style="padding: 10px 16px; color: #888888; font-size: 14px; text-align: justify;">
				<label>
					<span style="font-size: 16px;">{{$t('common.tipsTitle')}}：<br/></span>
					1. {{$t('withdraw.tips1')}}<br/>
					2. {{$t('withdraw.tips2')}}<br/>
					3. {{$t('withdraw.tips3')}}<br/>
					4. {{$t('withdraw.tips4')}}<br/>
				</label>
			</div>
			
			<div style="padding: 10px 16px; color: #888888; font-size: 14px; text-align: justify; width: 100%;">
				<label>
					<span style="font-size: 16px;">{{$t('common.safeTitle')}}：<br/></span>
					{{$t('transfer.tips1')}}<br/>
					{{$t('transfer.tips2')}}<br/>
					{{$t('transfer.tips3')}}<br/><br/>
					<span style="color: #FF0000;">{{$t('withdraw.tips5')}}</span>
				</label>
			</div>
		</div>
		
		<mt-popup style="max-width: 320px; width: 80%; align-items: center; border-radius: 10px;" v-model="confirmTipVisible" popup-transition="popup-fade">
			<WithdrawConfirmTip :tipInfo="tipInfo"></WithdrawConfirmTip>
		</mt-popup>
		
		<mt-popup style="max-width: 320px; width: 80%; align-items: center; border-radius: 10px;" v-model="successTipVisible" popup-transition="popup-fade">
			<WithdrawSuccessTip :tipInfo="tipInfo"></WithdrawSuccessTip>
		</mt-popup>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import { Indicator, Toast, MessageBox } from 'mint-ui';
	import Header from '../../../components/NavHeader.vue'
	import WithdrawConfirmTip from '../../../components/WithdrawConfirmTip.vue'
	import WithdrawSuccessTip from '../../../components/WithdrawSuccessTip.vue'
	
	export default {
		name: 'Withdraw',
		components: {
			Header,
			WithdrawConfirmTip,
			WithdrawSuccessTip
		},
		watch: {
			amount:function (val) {
				if(val >= 20 ) {
					this.realAmount = val - this.usdtFee
				}else {
					this.realAmount = 0
				}
			},
			netType(val) {
				// 切换网络
				if(val === 'erc20') {
					this.usdtFee = 10;
				}else{
					this.usdtFee = 0;
				}
			}
		},
		data() { 
			return {
				bg : {
					height: "100vh",
					width: "100%",
					backgroundImage: "url(" + require("../../../assets/login/new-bg.png") + ")",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "fixed",
					backgroundSize: "100% 100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					overflow: "auto"
				},
				gauth: localStorage.getItem('gauth'),
				address: '',
				amount: '',
				pwd: '',
				code: '',
				googleCode: '',
				usdtFee: '10',
				realAmount: '0',
				confirmTipVisible: false,
				successTipVisible: false,
				tipInfo: {
					amount: 0,
					address: ''
				},
				erc20Style: 'selected',
				arc20Style: 'unselected',
				netType: 'erc20',
				submitFlag: false
			}
		},
		methods: {
			
			showConfirmTip() {	
				let address = this.$data.address;
				let amount = this.$data.amount;
				let pwd = this.$data.pwd;
				let code = this.$data.code;
				
				if(null == address || '' == address) {
					Toast({
						message: this.$i18n.t("home.withdrawAddress_hint"),
						position: 'bottom'
					});
					return;
				}
				
				if(null == amount || '' == amount) {
					Toast({
						message: this.$i18n.t("home.withdrawAmount_hint"),
						position: 'bottom'
					});
					return;
				}
				
				if(amount <= 0) {
					Toast({
						message: this.$i18n.t("home.withdrawAmount_error"),
						position: 'bottom'
					});
					return;
				}
				
				if(amount < 20) {
					Toast({
						message: this.$i18n.t("home.withdrawUsdtAmount_error"),
						position: 'bottom'
					});
					return;
				}
				
				if(null == pwd || '' == pwd) {
					Toast({
						message: this.$i18n.t("assets.payPwd_hint"),
						position: 'bottom'
					});
					return;
				}
				
				if(null == code || '' == code) {
					Toast({
						message: this.$i18n.t("login.tac_hint"),
						position: 'bottom'
					});
					return;
				}
				
				this.$data.tipInfo = {
					amount: amount,
					address: address
				}
				this.$data.confirmTipVisible = true;
			},
			
			closePop() {
				this.$data.confirmTipVisible = false;
				this.$data.successTipVisible = false;
			},
			
			sendCode() {
				Indicator.open({
					text: '',
					spinnerType: 'fading-circle'
				});
				
				let phone = JSON.parse(localStorage.getItem('userInfo')).phone;
				let params = {
					phone: phone
				}
				this.$request.sendCode(
					params
				).then(()=>{
					Indicator.close();
				});
			},
			
			withdraw() {
				this.$data.confirmTipVisible = false;
				
				let address = this.$data.address;
				let amount = this.$data.amount;
				let pwd = this.$data.pwd;
				let code = this.$data.code;
				
				let params = {
					target: address,
					amount: amount,
					production: 'usdt',
					pwd: pwd,
					code: code,
					bak1: this.$data.netType,
					gauth: this.$data.googleCode
				}
				Indicator.open({
					text: '',
					spinnerType: 'fading-circle'
				});
				
				this.$request.withdraw(
					params
				).then(()=>{
					Indicator.close();
					sessionStorage.setItem('refreshInfo', '1');
					this.$data.successTipVisible = true;
				});
			},
			
			goBack() {
				this.$router.go(-1);
			},
			
			selectErc20() {
				this.$data.erc20Style = 'selected';
				this.$data.arc20Style = 'unselected';
				
				this.$data.netType = 'erc20';
				this.$data.submitFlag = false;
			},
			
			selectArc20() {
				this.$data.erc20Style = 'unselected';
				this.$data.arc20Style = 'selected';	
				
				this.$data.netType = 'arc20';
				this.$data.submitFlag = false;
				MessageBox(this.$i18n.t("common.tips"), '您选择的网络是AMBG生态链网络，目前仅支持提币至Bitdax交易所，如果不是提币到交易所， 您的资产将会丢失。');
				
				// this.$data.submitFlag = true;
				// MessageBox(this.$i18n.t("common.tips"), '正在接入中。。。');
			}
		}
	}
</script>

<style>
	.selected {
		width: 40%;
		height: 40px;
		margin: 20px 15px;
		margin-left: 0px;
		background-color: #009245;
		color: #FFFFFF;
		border-radius: 20px;
	}
	
	.unselected {
		width: 40%;
		height: 40px;
		margin: 20px 15px;
		background-color: #FFFFFF;
		color: #009245;
		border-radius: 20px;
		border: 1px solid #009245;
	}
</style>
